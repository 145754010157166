const Iconize = (s, kind = 'Outline') => {
	if (s.includes('Icon')) return s;
	if (typeof s == 'string')
		return (
			s.charAt(0).toUpperCase() +
			s.slice(1).replace(/-./g, (x) => x[1].toUpperCase()) +
			'Icon' +
			kind
		);
	else return '';
};

export { Iconize };
